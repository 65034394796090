import revive_payload_client_8ysRFX166d from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0VtoipFmx7 from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bBm9nbFQgy from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NrjB7HgGhR from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_XUt1lfAufe from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_d0NuRMK76x from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.4.5_vue@3.4.26_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/.nuxt/components.plugin.mjs";
import prefetch_client_dIaLJFiWXD from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/.nuxt/primevue-plugin.mjs";
import plugin_client_vAlB0x9EaS from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt-primevue@0.3.1_rollup@4.17.1_vue@3.4.26_typescript@5.4.5_/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_client_Ko7ITHlbQv from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_jw9DC0cPzU from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.1_vue@3.4.26_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_BtqC8sMYDd from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_axzTOhSqTR from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/01.sentry.ts";
import _02_tokens_wrFGe2jZkz from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/02.tokens.ts";
import _03_notifications_ohdHidFFIj from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/03.notifications.ts";
import _04_tooltip_LD8CWP4Lfg from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/plugins/04.tooltip.ts";
import _01_user_Z1phBQACOM from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/plugins/01.user.ts";
import _02_agreements_1VphlGUh67 from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/plugins/02.agreements.ts";
export default [
  revive_payload_client_8ysRFX166d,
  unhead_0VtoipFmx7,
  router_bBm9nbFQgy,
  payload_client_NrjB7HgGhR,
  check_outdated_build_client_XUt1lfAufe,
  plugin_vue3_d0NuRMK76x,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dIaLJFiWXD,
  primevue_plugin_egKpok8Auk,
  plugin_client_vAlB0x9EaS,
  plugin_client_Ko7ITHlbQv,
  i18n_jw9DC0cPzU,
  chunk_reload_client_BtqC8sMYDd,
  _01_sentry_axzTOhSqTR,
  _02_tokens_wrFGe2jZkz,
  _03_notifications_ohdHidFFIj,
  _04_tooltip_LD8CWP4Lfg,
  _01_user_Z1phBQACOM,
  _02_agreements_1VphlGUh67
]