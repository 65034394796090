import { default as index4IWsoJZYrAMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue?macro=true";
import { default as _91id_930AzexkJxkbMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue?macro=true";
import { default as _91id_935shkkfQps7Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue?macro=true";
import { default as _91id_93OsMDg5BTkUMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue?macro=true";
import { default as indexXhY4heehFfMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue?macro=true";
import { default as _91id_93XFwXnbDOJjMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue?macro=true";
import { default as authlqNdybz5zEMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue?macro=true";
import { default as copyright_45claim0M56SKtDacMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue?macro=true";
import { default as indexTahSc0M7X1Meta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue?macro=true";
import { default as _91id_93mRBBX7VJoIMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue?macro=true";
import { default as createSpJ8jS1OyVMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue?macro=true";
import { default as indexYiitrXYVftMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue?macro=true";
import { default as indexKII26ZB3LbMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue?macro=true";
import { default as createik9AEiDh2uMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue?macro=true";
import { default as indexUKOgvO84tkMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue?macro=true";
import { default as featuresG6r6lmZqzPMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue?macro=true";
import { default as manageltCU0Y4EvuMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue?macro=true";
import { default as overviewbPFcR2n9KfMeta } from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue?macro=true";
export default [
  {
    name: index4IWsoJZYrAMeta?.name ?? "apps-binary-packages",
    path: index4IWsoJZYrAMeta?.path ?? "/apps/binary-packages",
    meta: index4IWsoJZYrAMeta || {},
    alias: index4IWsoJZYrAMeta?.alias || [],
    redirect: index4IWsoJZYrAMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930AzexkJxkbMeta?.name ?? "apps-binary-packages-job-id",
    path: _91id_930AzexkJxkbMeta?.path ?? "/apps/binary-packages/job/:id()",
    meta: _91id_930AzexkJxkbMeta || {},
    alias: _91id_930AzexkJxkbMeta?.alias || [],
    redirect: _91id_930AzexkJxkbMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935shkkfQps7Meta?.name ?? "apps-binary-packages-manage-id",
    path: _91id_935shkkfQps7Meta?.path ?? "/apps/binary-packages/manage/:id()",
    meta: _91id_935shkkfQps7Meta || {},
    alias: _91id_935shkkfQps7Meta?.alias || [],
    redirect: _91id_935shkkfQps7Meta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OsMDg5BTkUMeta?.name ?? "apps-binary-packages-upload-id",
    path: _91id_93OsMDg5BTkUMeta?.path ?? "/apps/binary-packages/upload/:id()",
    meta: _91id_93OsMDg5BTkUMeta || {},
    alias: _91id_93OsMDg5BTkUMeta?.alias || [],
    redirect: _91id_93OsMDg5BTkUMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXhY4heehFfMeta?.name ?? "apps-identifiers",
    path: indexXhY4heehFfMeta?.path ?? "/apps/identifiers",
    meta: indexXhY4heehFfMeta || {},
    alias: indexXhY4heehFfMeta?.alias || [],
    redirect: indexXhY4heehFfMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XFwXnbDOJjMeta?.name ?? "apps-identifiers-manage-id",
    path: _91id_93XFwXnbDOJjMeta?.path ?? "/apps/identifiers/manage/:id()",
    meta: _91id_93XFwXnbDOJjMeta || {},
    alias: _91id_93XFwXnbDOJjMeta?.alias || [],
    redirect: _91id_93XFwXnbDOJjMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue").then(m => m.default || m)
  },
  {
    name: authlqNdybz5zEMeta?.name ?? "auth",
    path: authlqNdybz5zEMeta?.path ?? "/auth",
    meta: authlqNdybz5zEMeta || {},
    alias: authlqNdybz5zEMeta?.alias || [],
    redirect: authlqNdybz5zEMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: copyright_45claim0M56SKtDacMeta?.name ?? "copyright-claim",
    path: copyright_45claim0M56SKtDacMeta?.path ?? "/copyright-claim",
    meta: copyright_45claim0M56SKtDacMeta || {},
    alias: copyright_45claim0M56SKtDacMeta?.alias || [],
    redirect: copyright_45claim0M56SKtDacMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue").then(m => m.default || m)
  },
  {
    name: indexTahSc0M7X1Meta?.name ?? "developer-test-devices",
    path: indexTahSc0M7X1Meta?.path ?? "/developer/test-devices",
    meta: indexTahSc0M7X1Meta || {},
    alias: indexTahSc0M7X1Meta?.alias || [],
    redirect: indexTahSc0M7X1Meta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mRBBX7VJoIMeta?.name ?? "developer-test-devices-manage-id",
    path: _91id_93mRBBX7VJoIMeta?.path ?? "/developer/test-devices/manage/:id()",
    meta: _91id_93mRBBX7VJoIMeta || {},
    alias: _91id_93mRBBX7VJoIMeta?.alias || [],
    redirect: _91id_93mRBBX7VJoIMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue").then(m => m.default || m)
  },
  {
    name: createSpJ8jS1OyVMeta?.name ?? "developer-tokens-create",
    path: createSpJ8jS1OyVMeta?.path ?? "/developer/tokens/create",
    meta: createSpJ8jS1OyVMeta || {},
    alias: createSpJ8jS1OyVMeta?.alias || [],
    redirect: createSpJ8jS1OyVMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue").then(m => m.default || m)
  },
  {
    name: indexYiitrXYVftMeta?.name ?? "developer-tokens",
    path: indexYiitrXYVftMeta?.path ?? "/developer/tokens",
    meta: indexYiitrXYVftMeta || {},
    alias: indexYiitrXYVftMeta?.alias || [],
    redirect: indexYiitrXYVftMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue").then(m => m.default || m)
  },
  {
    name: indexKII26ZB3LbMeta?.name ?? "index",
    path: indexKII26ZB3LbMeta?.path ?? "/",
    meta: indexKII26ZB3LbMeta || {},
    alias: indexKII26ZB3LbMeta?.alias || [],
    redirect: indexKII26ZB3LbMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue").then(m => m.default || m)
  },
  {
    name: createik9AEiDh2uMeta?.name ?? "legal-copyright-infringements-create",
    path: createik9AEiDh2uMeta?.path ?? "/legal/copyright-infringements/create",
    meta: createik9AEiDh2uMeta || {},
    alias: createik9AEiDh2uMeta?.alias || [],
    redirect: createik9AEiDh2uMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue").then(m => m.default || m)
  },
  {
    name: indexUKOgvO84tkMeta?.name ?? "legal-copyright-infringements",
    path: indexUKOgvO84tkMeta?.path ?? "/legal/copyright-infringements",
    meta: indexUKOgvO84tkMeta || {},
    alias: indexUKOgvO84tkMeta?.alias || [],
    redirect: indexUKOgvO84tkMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue").then(m => m.default || m)
  },
  {
    name: featuresG6r6lmZqzPMeta?.name ?? "legal-features",
    path: featuresG6r6lmZqzPMeta?.path ?? "/legal/features",
    meta: featuresG6r6lmZqzPMeta || {},
    alias: featuresG6r6lmZqzPMeta?.alias || [],
    redirect: featuresG6r6lmZqzPMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue").then(m => m.default || m)
  },
  {
    name: manageltCU0Y4EvuMeta?.name ?? "legal-info-manage",
    path: manageltCU0Y4EvuMeta?.path ?? "/legal/info/manage",
    meta: manageltCU0Y4EvuMeta || {},
    alias: manageltCU0Y4EvuMeta?.alias || [],
    redirect: manageltCU0Y4EvuMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue").then(m => m.default || m)
  },
  {
    name: overviewbPFcR2n9KfMeta?.name ?? "legal-overview",
    path: overviewbPFcR2n9KfMeta?.path ?? "/legal/overview",
    meta: overviewbPFcR2n9KfMeta || {},
    alias: overviewbPFcR2n9KfMeta?.alias || [],
    redirect: overviewbPFcR2n9KfMeta?.redirect,
    component: () => import("/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue").then(m => m.default || m)
  }
]