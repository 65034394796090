import validate from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/pages/runtime/validate.js";
import tokens_45global from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/middleware/tokens.global.ts";
import auth_45global from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/middleware/auth.global.ts";
import manifest_45route_45rule from "/Users/zond80/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_axios@1.6.8_encodi_dmpizzv2v5qqzakvgiljwnamxy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  tokens_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}